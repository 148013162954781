import { log } from "console";

import { ToastrService } from "ngx-toastr";
import { Observable, Subject, concat, of } from "rxjs";
import {
    catchError,
    debounceTime,
    distinctUntilChanged,
    map,
    switchMap,
    tap,
} from "rxjs/operators";

import { Component, Input, OnInit } from "@angular/core";
import {
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from "@angular/forms";
import { NgbActiveModal, NgbDateAdapter } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";

import { DrupalizeObject } from "../../shared/common/drupalize-object";
import { NgbDateDrupalAdapter } from "../../shared/common/ngb-date-drupal-adapter";
import { UnDrupalizeObject } from "../../shared/common/undrupalize-object";
import { IInvoiceItem, Invoice } from "../models/invoice-model";
import { Requisites } from "../models/requisites";
import { FetchInvoicesService } from "../services/fetch-invoices.service";

export interface ApplicationItem {
    id: number;
    target_label: string;
}

@Component({
    selector: "app-invoice-form",
    templateUrl: "./invoice-form.component.html",
    styleUrls: ["./invoice-form.component.scss"],
    providers: [{ provide: NgbDateAdapter, useClass: NgbDateDrupalAdapter }],
})
export class InvoiceFormComponent implements OnInit {
    @Input()
    invoice: Invoice;
    public invoice_form: FormGroup;
    public title = "INVOICES.CREATE_INVOICE";
    // TODO Change to Application once had occasion
    public application$: Observable<ApplicationItem[]>;
    public applicationLoading = false;
    public applicationinput$ = new Subject<string>();

    public user$: Observable<ApplicationItem[]>;
    public userLoading = false;
    public userinput$ = new Subject<string>();

    public saving = false;

    public currencies = [{ value: "UAH" }, { value: "USD" }, { value: "EUR" }, { value: "GBP" }];
    public sellers = [];
    public invoice_types = [
        { value: "process_service", name: "INVOICE_TYPES.PROCESS_SERVICE" },
        { value: "search", name: "INVOICE_TYPES.SEARCH" },
        { value: "support", name: "INVOICE_TYPES.SUPPORT" },
        { value: "update_service", name: "INVOICE_TYPES.UPDATE_SERVICE" },
        { value: "custom", name: "INVOICE_TYPES.CUSTOM" },
        { value: "fee", name: "INVOICE_TYPES.FEE" },
    ];
    public serviceTypes = [];

    public payer_types = [
        { value: "individual", name: "COMMON.INDIVIDUAL" },
        { value: "organization", name: "COMMON.LEGAL" },
    ];
    public withRequisites = new FormControl(false);
    manualDescriptionColapsed = true;
    requisitesColapsed = true;

    requisites = new Requisites();

    get items(): FormArray {
        return this.invoice_form.get("items") as FormArray;
    }

    constructor(
        public activeModal: NgbActiveModal,
        private formBuilder: FormBuilder,
        public invoicesService: FetchInvoicesService,
        public toastr: ToastrService,
        public translate: TranslateService
    ) {
        this.invoice_form = this.formBuilder.group({
            id: [""],
            name: ["{auto}", Validators.required],
            invoice_description: [""],
            amount: [0.0, Validators.required],
            currency: ["UAH", Validators.required],
            service: this.formBuilder.group({
                target_id: [""],
                target_type: ["application", Validators.required],
                target_label: [""],
            }),
            type: ["custom"],
            seller: ["marina"],
            payed: [null],
            user_id: [""],
            payer_type: ["individual"],
            payer_requisites: [""],
            no_vat: [false],
            items: this.formBuilder.array([]),
        });

        this.withRequisites.valueChanges.subscribe(
            (data) => {
                if (data) {
                    this.invoice_form.get("type").patchValue("custom");
                    this.invoice_form.get("type").disable();
                } else {
                    this.invoice_form.get("type").enable();
                }
            },
            (err) => console.log(err)
        );

        this.invoicesService.query("/api/v1/seller/list", {}).subscribe(
            (data) => {
                if (data.rows) {
                    this.sellers = [
                        ...Object.keys(data.rows).map((key) => ({
                            value: data.rows[key].id,
                            name: data.rows[key].label,
                        })),
                        { value: "", name: "COMMON.NOT_SPECIFIED" },
                    ];
                }
            },
            (err) => {}
        );
    }

    ngOnInit() {
        const base_items = [];
        if (this.invoice) {
            if (!this.invoice.service || !this.invoice.service.target_id) {
                this.withRequisites.patchValue(true);
            }
            if (
                this.invoice.user_id &&
                this.invoice.user_id !== "" &&
                typeof this.invoice.user_id === "string"
            ) {
                this.invoice.user_id = Number(this.invoice.user_id);
            }
            this.title = "INVOICES.EDIT_INVOICE";

            const { items, requisites, ...invoice } = this.invoice;

            this.invoice_form.patchValue(invoice);
            for (const item of items ?? [null]) {
                this.items.push(this.getInvoiceItemForm(item));
            }
            this.requisites = new Requisites(requisites);
            if (
                this.invoice.pay_date &&
                this.invoice.pay_date !== "Не оплачен"
            ) {
                this.invoice_form
                    .get("payed")
                    .patchValue(this.invoice.pay_date);
            } else {
                this.invoice_form.get("payed").patchValue(null);
            }

            base_items.push({
                id: this.invoice.service.target_id,
                target_label: this.invoice.service.target_label,
            });

            if (this.invoice.user_id && this.invoice.user_id !== "") {
                this.invoicesService.getUser(this.invoice.user_id).subscribe(
                    (data) => {
                        const user = UnDrupalizeObject.format(data) as any;
                        const base_user = [
                            {
                                fullname:
                                    user.field_forename +
                                    " " +
                                    user.field_surname,
                                uid: user.uid,
                            },
                        ];
                        this.setUsersSearch(base_user);
                    },
                    (err) => {
                        console.log(err);
                        this.setUsersSearch([]);
                    }
                );
            } else {
                this.setUsersSearch([]);
            }
        } else {
            this.setUsersSearch([]);
        }

        this.invoicesService.getServiceTypes().subscribe(
            (data) => {
                for (const item of data) {
                    if (item.value !== "All" && item.value !== "no_service") {
                        this.serviceTypes.push(item.value);
                    }
                }
            },
            (err) => console.log(err)
        );
        const type = this.invoice_form.value.service
            ? this.invoice_form.value.service.target_type
            : "application";

        this.application$ = concat(
            of(base_items), // default items
            this.applicationinput$.pipe(
                debounceTime(200),
                distinctUntilChanged(),
                tap(() => (this.applicationLoading = true)),
                switchMap((term) =>
                    this.invoicesService.getServices(term, "all", type).pipe(
                        catchError(() => of([])), // empty list on error
                        tap(() => (this.applicationLoading = false))
                    )
                )
            )
        );

        this.invoice_form
            .get("service")
            .get("target_type")
            .valueChanges.subscribe(
                (data) => {
                    this.application$ = concat(
                        of(base_items), // default items
                        this.applicationinput$.pipe(
                            debounceTime(200),
                            distinctUntilChanged(),
                            tap(() => (this.applicationLoading = true)),
                            switchMap((term) =>
                                this.invoicesService
                                    .getServices(term, "all", data)
                                    .pipe(
                                        catchError(() => of([])), // empty list on error
                                        tap(
                                            () =>
                                                (this.applicationLoading =
                                                    false)
                                        )
                                    )
                            )
                        )
                    );
                },
                (err) => {}
            );
    }

    setUsersSearch(base_items) {
        this.user$ = concat(
            of(base_items),
            this.userinput$.pipe(
                debounceTime(200),
                distinctUntilChanged(),
                tap(() => (this.userLoading = true)),
                switchMap((term) =>
                    this.invoicesService.getUsers(term).pipe(
                        catchError(() => of([])), // empty list on error
                        tap(() => (this.userLoading = false))
                    )
                )
            )
        );
    }

    saveInvoice() {
        if (!this.invoice_form.valid) {
            this.translate
                .get("ALERTS.FILL_ALL_FIELDS")
                .subscribe((res: string) => {
                    this.toastr.error(res);
                });
            return;
        }
        const invoice_payload = DrupalizeObject.format(this.invoice_form.value);
        invoice_payload["type"] = [this.invoice_form.value.type];
        if (
            !this.invoice_form.value.service.target_label ||
            this.invoice_form.value.service.target_label === ""
        ) {
            delete invoice_payload["service"].target_label;
        }
        if (this.withRequisites.value === true) {
            invoice_payload["type"] = [{ value: "custom" }];
        } else {
            delete invoice_payload["payer_type"];
            delete invoice_payload["payer_requisites"];
        }
        invoice_payload["requisites"] = [
            { value: JSON.stringify(this.requisites) },
        ];

        this.saving = true;
        this.invoicesService
            .save(this.invoice_form.value.id, invoice_payload, "invoice")
            .subscribe(
                (m) => {
                    this.translate
                        .get("INVOICES.INVOICE_SAVED")
                        .subscribe((res: string) => {
                            this.toastr.success(res);
                        });
                    this.activeModal.close("After successful save");
                },
                (e) => {
                    this.translate
                        .get("INVOICES.ERROR_CREATING_INVOICE")
                        .subscribe((res: string) => {
                            this.toastr.error(res);
                        });
                    console.log(e.error.message);
                    this.toastr.error(e.error ? e.error.message : "");
                }
            );
    }

    /**
     * Drupal payload formater
     *
     * @param payload object containe Drupal entity fields
     */
    formatDrupalArray(payload) {
        const drupalized_payload = {};
        for (const param in payload) {
            if (
                payload.hasOwnProperty(param) &&
                payload[param] !== "" &&
                param !== "id"
            ) {
                drupalized_payload[param] = [];
                if (param === "service") {
                    drupalized_payload[param].push(payload[param]);
                } else {
                    drupalized_payload[param].push({
                        value: payload[param],
                    });
                }
            }
        }
        return drupalized_payload;
    }

    addInvoiceItem() {
        this.items.push(this.getInvoiceItemForm());
    }

    removeInvoiceItem(index: number) {
        this.items.removeAt(index);
    }

    private getInvoiceItemForm(item?: IInvoiceItem) {
        return this.formBuilder.group({
            description: [item?.description ?? ""],
            quantity: [item?.quantity ?? 1],
            amount: [item?.amount ?? 0],
            taxable: [item?.taxable === "1" ? true : false],
        });
    }
}
